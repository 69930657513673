var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',[[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.module)+" by "+_vm._s(_vm.moduleTitle)+" ("+_vm._s(_vm.addTSBVZ(_vm.totalRecords))+") "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchQueryData.apply(null, arguments)}}})],1),(_vm.isLoaded)?_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"type":"table"}}):_vm._e(),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoaded),expression:"!isLoaded"}],staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.totalRecords,"footer-props":{
                  itemsPerPageOptions: _vm.rowsPerPageItems,
                  showFirstLastPage: true,
                },"loading":_vm.loading,"mobile-breakpoint":"100"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.full_title",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('p',{domProps:{"innerHTML":_vm._s(item.full_title)}})])]}},{key:"item.count",fn:function(ref){
                var item = ref.item;
return [(item.hasOwnProperty('leads_count'))?_c('div',{staticClass:"text-truncate"},[_c('p',{domProps:{"innerHTML":_vm._s(item.leads_count)}})]):_vm._e(),(item.hasOwnProperty('assigned_leads_count'))?_c('div',{staticClass:"text-truncate"},[_c('p',{domProps:{"innerHTML":_vm._s('Active: ' + item.assigned_leads_count)}}),_c('p',{domProps:{"innerHTML":_vm._s('Closed: ' + item.closed_leads_count)}})]):_vm._e(),(item.hasOwnProperty('listings_count'))?_c('div',{staticClass:"text-truncate"},[_c('p',{domProps:{"innerHTML":_vm._s(item.listings_count)}})]):_vm._e(),(item.hasOwnProperty('assigned_listings_count'))?_c('div',{staticClass:"text-truncate"},[_c('p',{domProps:{"innerHTML":_vm._s(item.assigned_listings_count)}})]):_vm._e()]}}])})],1)]],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }