<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-card-title>
                {{ module }} by {{ moduleTitle }} ({{ addTSBVZ(totalRecords) }})
                <v-spacer></v-spacer>
                <v-text-field
                    @keydown.enter="searchQueryData"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-card-title>
              <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"
              >
              </v-skeleton-loader>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalRecords"
                  :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="100"
                  class="elevation-0">
                <template v-slot:item.full_title="{ item }">
                  <div class="text-truncate">
                    <p v-html="item.full_title"></p>
                  </div>
                </template>
                <template v-slot:item.count="{ item }">
                  <div class="text-truncate" v-if="item.hasOwnProperty('leads_count')">
                    <p v-html="item.leads_count"></p>
                  </div>
                  <div class="text-truncate" v-if="item.hasOwnProperty('assigned_leads_count')">
                    <p v-html="'Active: ' + item.assigned_leads_count"></p>
                    <p v-html="'Closed: ' + item.closed_leads_count"></p>
                  </div>
                  <div class="text-truncate" v-if="item.hasOwnProperty('listings_count')">
                    <p v-html="item.listings_count"></p>
                  </div>
                  <div class="text-truncate" v-if="item.hasOwnProperty('assigned_listings_count')">
                    <p v-html="item.assigned_listings_count"></p>
                  </div>
                </template>
              </v-data-table>
              <!-- this dialog is used for both create and update -->
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {LEADS_DASHBOARD_ALL_COUNT} from "@/core/services/store/leads.module";
import {LISTINGS_DASHBOARD_ALL_COUNT} from "@/core/services/store/listings.module";

export default {
  name: 'dashboard-view-all-count',
  props: ['module', 'moduleTitle'],
  data() {
    return {
      headers: [
        {text: 'Title', value: 'full_title', name: 'full_title', filterable: true, sortable: false},
        {text: 'No. Of ' + this.module, value: 'count', name: 'count', filterable: false, sortable: false},
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      items: [],
    }
  },
  watch: {
    moduleTitle: {
      handler(v) {
        if (v) {
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
        }
      },
      deep: true
    },
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].value +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      let method = ''
      let methodType = ''
      if (this.module === 'Leads') {
        method = LEADS_DASHBOARD_ALL_COUNT
        if (this.moduleTitle === 'Developments') {
          methodType = 'development'
        }
        if (this.moduleTitle === 'Projects') {
          methodType = 'project'
        }
        if (this.moduleTitle === 'Agents') {
          methodType = 'user'
        }
        if (this.moduleTitle === 'Status') {
          methodType = 'status'
        }
      }
      if (this.module === 'Listings') {
        method = LISTINGS_DASHBOARD_ALL_COUNT
        if (this.moduleTitle === 'Developments') {
          methodType = 'development'
        }
        if (this.moduleTitle === 'Projects') {
          methodType = 'project'
        }
        if (this.moduleTitle === 'Agents') {
          methodType = 'user'
        }
        if (this.moduleTitle === 'Status') {
          methodType = 'status'
        }
      }
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&type=" + methodType +
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 1 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(method, {q: query, columns: this.columns}).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    }
  }
};
</script>